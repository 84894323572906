import { startTransition, StrictMode, useEffect } from 'react';
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';

import { browserTracingIntegration, init, replayIntegration } from '@sentry/remix';
import { hydrateRoot } from 'react-dom/client';

import { publicEnv } from '~/constants/env-client';


const { ENV } = publicEnv();

// Init Sentry client-side
init({
  dsn: 'https://8afc2f4572299067a1eabdebad4b5603@o4507188340981760.ingest.us.sentry.io/4507499645960192',
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  environment: ENV,
  enabled: ENV === 'dev' || ENV === 'prod',
  integrations: [
    browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    replayIntegration(),
  ],
  beforeSend(event) {
    if (event.request?.url) {
      const url = new URL(event.request.url);
      // Ignore all browser extension errors
      if (url.protocol === 'chrome-extension:' || url.protocol === 'moz-extension:') {
        return null;
      }
    }

    return event;
  },
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
